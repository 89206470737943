import BrowserStorage from '../../helpers/browser-storage'
import EventBus from '../../helpers/EventBus'
import { snakeToCamel } from '../../helpers/string-helper'


export default class TagManager extends HTMLElement {
    private cookieName: string = 'hasAnalyticsConsent'
    private isProduction: string

    /**
     * The browser calls this method when the element is
     * added to the DOM.
     */
    connectedCallback() {
        console.log('Tag Manager: Connected')
        this.initializeComponent()
    }

    initializeComponent() {
        //Properties
        this.isProduction = this['isProduction']

        //Events
        EventBus.$on('cookieConsent', () => {
            console.log("Tag Manager: Received Event 'cookieConsent'. Reconsider lanching tags.")
            this.launchIfAllowed()
        })

        this.launchIfAllowed()
    }

    private launchIfAllowed() {
        if (BrowserStorage.getBooleanCookie(this.cookieName)) {
            this.launch()
        } else {
            this.destroy()
        }
    }

    /**
     * Add all attributes ot you web component.
     * For attributeChangedCallback() callback to fire when an attribute changes, you have to observe the attributes.
     */
    static get observedAttributes() {
        return ['is-production']
    }

    /**
     * Called before connectedCallback() for every observed attribute
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            for (const item of TagManager.observedAttributes) {
                if (item === name) {
                    const propertyName = snakeToCamel(item)
                    this[propertyName] = newValue //Set text in property
                }
            }
        }
    }

    launch() {
        if (!this.isProduction || this.isProduction.toLowerCase() === 'true') {
            console.log('TagManager: Launch scripts')
            /**
             * INSERT ALL TRACKING CODE HERE
             */

            //Google Analytics
            //const script = document.createElement('script');
            //script.innerHTML = `window.dataLayer = window.dataLayer || [];
            //                    function gtag(){dataLayer.push(arguments);}
            //                    gtag('js', new Date());
            //                    gtag('config', 'YOUR_GOOGLE_ID', {'anonymize_ip': true});`;
            // document.body.appendChild(script);
        } else {
            console.log(
                'TagManager: NOT launching scripts because we are NOT on production',
            )
        }
    }

    destroy() {
        console.log('TagManager: Destroy')
        // Remove all component-specific Cookies
        BrowserStorage.clearCookiesStartWith('_ga') // Google Analytics
        BrowserStorage.clearCookiesStartWith('_gid') // Google Analytics
        BrowserStorage.clearCookiesStartWith('_gcl_au') // GTM's Conversion Linker, set Google Tracking on 1st party
    }
}
