class OiFancyBox {
    private fancybox: HTMLElement;
    private modalFancy: HTMLElement;
    private modalBackdrop: HTMLElement;
    private currentButton: HTMLButtonElement | null;
    private closeBtn: HTMLButtonElement;
    private modalText: HTMLElement | null;
    private onLoadButton: HTMLButtonElement | null;
    private buttons: NodeListOf<HTMLButtonElement>;
    private buttonKey: string;
    private currentIndex: number;
    private touchStartX: number;
    private touchEndX: number;

    constructor(fancybox: HTMLElement) {
        this.fancybox = fancybox;
        this.buttons = fancybox.querySelectorAll("button");
        this.modalFancy = fancybox.querySelector(".fancybox") as HTMLElement;
        this.modalText = fancybox.querySelector("#fancyboxText") as HTMLImageElement;
        this.modalBackdrop = fancybox.querySelector("#fancyboxBackdrop") as HTMLElement;
        this.closeBtn = fancybox.querySelector("#fancyboxClose") as HTMLButtonElement;

        // Find the image if the hash matches
        if (window.location.hash) {
            this.onLoadButton = Array.from(this.buttons).find((btn) => btn.id.includes(window.location.hash.substring(1))) || null;
        }

        for (const button of this.buttons) {
            button.addEventListener("click", () => this.showTextInModal(button));
        }

        if (this.modalBackdrop) {
            this.modalBackdrop.addEventListener("click", this.removeHash.bind(this));
        }

        if (this.closeBtn) {
            this.closeBtn.addEventListener("click", this.removeHash.bind(this));
        }

        this.currentButton = null;
        this.currentIndex = -1;
        this.touchStartX = 0;
        this.touchEndX = 0;
    }

    showTextInModal(button: HTMLButtonElement) {
        this.currentButton = button;
        this.currentIndex = Array.from(this.buttons).indexOf(button);
        this.showText(button.getAttribute('data-profil'));
    }

    showText(id: string) {
        this.buttonKey = id;
        this.modalFancy.id = this.buttonKey;
        if (this.modalText && this.fancybox.querySelector("#js-profil-" + id)) {
            this.modalText.innerHTML = this.fancybox.querySelector("#js-profil-" + id).innerHTML;
            this.modalText.classList.remove('opacity-0');
        }

        window.location.hash = this.buttonKey;

        this.modalFancy.classList.add('is-open');
        document.documentElement.classList.add('modal-open'); // Block scrolling
    }

    removeHash() {
        this.modalFancy.classList.remove('is-open');
        document.documentElement.classList.remove('modal-open'); // Enable scrolling

        setTimeout(() => {
            const scrollY = window.scrollY;
            window.location.hash = '';
            this.onLoadButton = null;
            this.buttonKey = 'fancybox';
            this.modalFancy.id = this.buttonKey;

            if (this.modalText) {
                this.modalText.classList.add('opacity-0');
                this.modalText.innerHTML = '';
            }

            window.scrollTo(0, scrollY);
        }, 250);
    }
}

export default OiFancyBox;
