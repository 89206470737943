import { Loader } from '@googlemaps/js-api-loader'
import { createTemplate } from '../../helpers/create-template.js'
import EventBus from '../../helpers/EventBus'
import { snakeToCamel } from '../../helpers/string-helper'
import BrowserStorage from '../../helpers/browser-storage'


const template: HTMLTemplateElement | null = createTemplate(`
    <template>
        <style>
            .google-map {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                min-height: 500px
            }
            .is-hidden {
                display: none;
            }

            .google-map > .map {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        </style>

        <div class="google-map">
            <div class="js-map map is-hidden"></div>
            <div class="js-cookie-message cookie-message is-hidden bg-gray-200 p-4">
                <p>
                    <span data-attribute="cookie-text">Please accept cookies to see google map.</span><br>
                    <a data-attribute="cookie-link" class="js-open-cookie-banner">Cookie-Settings</a>
                </p>
            </div>
        </div>

    </template>
`)

export default class GoogleMap extends HTMLElement {
    private cookieName: string = 'hasAnalyticsConsent'
    private loader: any // Define loader property
    private mapContainer: HTMLElement
    private cookieMessage: HTMLElement

    //Properties
    private lat: number
    private long: number
    private apiKey: string
    private zoom: number
    private markerIcon: string
    private markerWidth: number
    private markerHeight: number
    private info: string
    private styles: string

    /**
     * Add all attributes ot you web component.
     * For attributeChangedCallback() callback to fire when an attribute changes, you have to observe the attributes.
     */
    static get observedAttributes() {
        return [
            'lat',
            'long',
            'api-key',
            'zoom',
            'marker-icon',
            'marker-width',
            'marker-height',
            'info',
            'cookie-text',
            'cookie-link',
            'styles',
        ]
    }

    /**
     * The browser calls this method when the element is
     * added to the DOM.
     */
    connectedCallback() {
        console.log('Google Map: Connected')
        this.appendChild(template.content.cloneNode(true))
        this.updateTextsfromAttributes()
        this.initializeComponent()
    }

    /**
     * Called before connectedCallback() for every observed attribute
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            for (const item of GoogleMap.observedAttributes) {
                if (item === name) {
                    const propertyName = snakeToCamel(item)
                    this[propertyName] = newValue //Set text in property
                }
            }
        }
        this.updateTextsfromAttributes()
    }

    /**
     * Sets texts of tags with a data-attribute with webcomponents attribute value
     */
    updateTextsfromAttributes() {
        GoogleMap.observedAttributes.forEach((item) => {
            const propertyName = snakeToCamel(item)
            const selector = '[data-attribute=' + item + ']'
            this.querySelectorAll(selector).forEach((item) => {
                item.innerHTML = this[propertyName] //Get text from property and write to HTML
            })
        })
    }

    initializeComponent() {
        //Elements
        this.mapContainer = this.querySelector('.js-map')
        this.cookieMessage = this.querySelector('.js-cookie-message')

        //Properties
        this.lat = Number(this['lat'])
        this.long = Number(this['long'])
        this.apiKey = this['apiKey']
        this.zoom = Number(this['zoom']);
        this.markerIcon = this['markerIcon']
        this.markerWidth = Number(this['markerWidth'])
        this.markerHeight = Number(this['markerHeight'])
        this.info = this['info']
        this.styles = this['styles']

        //Events
        EventBus.$on('cookieConsent', () => {
            console.log('Google Map: Received Event cookieConsent. Reconsider showing the map.')
            this.launchIfAllowed()
        })

        this.launchIfAllowed()
    }

    private launchIfAllowed() {
        if(BrowserStorage.getBooleanCookie(this.cookieName)) {
            this.loadMap()
        } else {
            this.showCookies()
        }
    }

    loadMap() {

        // Load Google Maps API
        this.loader = new Loader({
            apiKey: this.apiKey,
            version: 'weekly', // or specify a version (e.g., '3.45')
        })

        this.loader
            .load()
            .then(() => {
                console.log('Google Map: Google Maps API loaded')
                this.createMap()
            })
            .catch((error) => {
                console.error(
                    'Google Map: Failed to load Google Maps API',
                    error,
                )
                return
            })

    }

    createMap() {

        // Define your custom styles
        // console.log("Styles:" + this.styles)
        const customMapStyles:  google.maps.MapTypeStyle[] = JSON.parse(this.styles);

        // Create Map
        const map = new google.maps.Map(this.mapContainer, {
            gestureHandling: 'cooperative',
            clickableIcons: false,
            zoom: this.zoom,
            center: { lat: this.lat, lng: this.long },
            // Custom styling from https://mapstyle.withgoogle.com/
            styles: customMapStyles
        });

        let icon : google.maps.Icon
        if(this.markerIcon) {
            // Create Icon
            icon =
                {
                    url: this.markerIcon,
                    scaledSize: new google.maps.Size(
                        this.markerWidth,
                        this.markerHeight,
                    ),
                    anchor: new google.maps.Point(
                        this.markerWidth / 2,
                        this.markerHeight,
                    ),
                }
        }

        const marker = new google.maps.Marker({
            position: { lat: this.lat, lng: this.long },
            map: map,
            optimized: false,
            icon: icon
        });

        // Create Info Window
        if(this.info) {
            const infoWindow = new google.maps.InfoWindow({content: this.info});
            infoWindow.open(map, marker);
            google.maps.event.addListener(marker, "click", () =>
                infoWindow.open(map, marker),
            );
        }
        this.showMap()
    }

    private showMap() {
        this.mapContainer.classList.remove("is-hidden")
        this.cookieMessage.classList.add("is-hidden")
    }

    private showCookies() {
        this.mapContainer.classList.add("is-hidden")
        this.cookieMessage.classList.remove("is-hidden")
    }
}
