class OiForm {
    private oiFormElement: HTMLElement;
    private formElement: HTMLFormElement | null;
    private successMessageElement: HTMLElement | null;
    private sendingMessageElement: HTMLElement | null;
    private errorMessageElement: HTMLElement | null;
    private submitElement: HTMLInputElement | null;
    private honeyPotElement: HTMLInputElement | null;
    private plusBtns: NodeListOf<Element>;
    private minusBtns: NodeListOf<Element>;

    constructor(oiFormElement: HTMLElement) {
        console.log('OiForm: Constructor');
        this.oiFormElement = oiFormElement;

        this.submitElement = this.oiFormElement.querySelector('input[type="submit"]');
        this.formElement = this.oiFormElement.querySelector('form');
        if (this.formElement && this.submitElement) {
            this.successMessageElement = this.oiFormElement.querySelector('.js-form-message-success');
            this.sendingMessageElement = this.oiFormElement.querySelector('.js-form-message-sending');
            this.errorMessageElement = this.oiFormElement.querySelector('.js-form-message-error');

            this.plusBtns = this.oiFormElement.querySelectorAll('.js-fieldset-plus');
            this.minusBtns = this.oiFormElement.querySelectorAll('.js-fieldset-minus');

            // Clear honeypot
            this.honeyPotElement = this.oiFormElement.querySelector('.js-honey-pot') as HTMLInputElement;
            this.clearHoneyPot();

            // Form submission
            this.formElement.addEventListener('submit', this.handleSubmit.bind(this));

            for (let btn of this.plusBtns) {
                btn.addEventListener('click', this.showFieldset.bind(this));
            }

            for (let btn of this.minusBtns) {
                btn.addEventListener('click', this.clearFieldset.bind(this));
            }
        } else {
            console.log('OiForm: Form or Submit missing');
        }
    }

    clearHoneyPot() {
        if (this.honeyPotElement) {
            this.honeyPotElement.value = '';
        }
    }

    private async handleSubmit(event: Event) {
        event.preventDefault();
        console.log('OiForm: handleSubmit');

        // Display sending message
        this.showSendingMessage();

        // Get form data
        const formData = new FormData(this.formElement!);

        // Get the form action URL
        const formAction = this.formElement!.getAttribute('action') || '';

        try {
            const response = await fetch(formAction, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                this.showSuccessMessage();
            } else {
                this.showErrorMessage();
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            this.showErrorMessage();
        }
    }

    private showFieldset(event: Event) {
        const target = event.currentTarget as HTMLElement;
        const fieldset = target.parentNode as HTMLElement;
        const fieldsetContent = fieldset.firstElementChild as HTMLElement;
        const prevFieldsetFields = fieldset.previousElementSibling?.querySelectorAll('input, textarea') || [];
        let canExpand = true;

        /*
        if(prevFieldsetFields.length) {
            canExpand = false
        }
        for (const field of prevFieldsetFields) {
            if ((field as HTMLInputElement).value) {
                canExpand = true;
                break;
            }
        } */

        if (canExpand) {
            fieldset.classList.add('expanded');
            fieldsetContent.classList.remove('invisible');
        }
    }

    private clearFieldset(event: Event) {
        const target = event.currentTarget as HTMLElement;
        const fieldset = target.parentNode as HTMLElement;
        let fieldsetContent: HTMLElement;
        let fieldsetFields: NodeListOf<HTMLInputElement | HTMLTextAreaElement>;

        fieldset.classList.remove('expanded');
        fieldsetContent = fieldset.firstElementChild as HTMLElement;
        fieldsetFields = fieldsetContent.querySelectorAll('input, textarea');

        for (const field of fieldsetFields) {
            (field as HTMLInputElement).value = '';
        }
        fieldsetContent.classList.add('invisible');

        let tmpFieldset = fieldset.nextElementSibling as HTMLElement;
        while (tmpFieldset && tmpFieldset.tagName.toLowerCase() === 'fieldset') {
            tmpFieldset.classList.remove('expanded');
            fieldsetContent = tmpFieldset.firstElementChild as HTMLElement;
            fieldsetFields = fieldsetContent.querySelectorAll('input, textarea');

            for (const field of fieldsetFields) {
                (field as HTMLInputElement).value = '';
            }
            fieldsetContent.classList.add('invisible');

            tmpFieldset = tmpFieldset.nextElementSibling as HTMLElement;
        }
    }

    private showSendingMessage() {
        if (this.sendingMessageElement) {
            this.sendingMessageElement.classList.remove('hidden');
        }
        if (this.submitElement) {
            this.submitElement.setAttribute('disabled', 'disabled');
        }
    }

    private showSuccessMessage() {
        if (this.sendingMessageElement) {
            this.sendingMessageElement.classList.add('hidden');
        }
        if (this.successMessageElement) {
            this.successMessageElement.classList.remove('hidden');
        }
    }

    private showErrorMessage() {
        if (this.sendingMessageElement) {
            this.sendingMessageElement.classList.add('hidden');
        }
        if (this.errorMessageElement) {
            this.errorMessageElement.classList.remove('hidden');
        }
    }
}

export default OiForm;
