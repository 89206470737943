/* --- Application --- */
import './css/global.css'
import CookieConsent from './components/cookie-consent/cookieConsent'
import GoogleMap from './components/google-map/googleMap'
import TagManager from './components/tag-manager/tagManager'
import OiForm from './components/oi-form/OiForm'
import OiSnapSlider from './components/oi-snap-slider/OiSnapSlider'
import OiGallery from './components/oi-gallery/OiGallery'
import OiFancyBox from './components/oi-fancybox/OiFancyBox'
import EventBus from './helpers/EventBus'

customElements.define('cookie-consent', CookieConsent)
customElements.define('google-map', GoogleMap)
customElements.define('tag-manager', TagManager)


 // Add the class js-open-cookie-banner on any element. A click on it opens the cookie banner
document.querySelectorAll('.js-open-cookie-banner').forEach((element) => {
    element.addEventListener('click', () => {
        EventBus.$emit('openCookieBanner')
    })
})


 // Open main navigation if you click on the hamburger
const toggleMenu = document.querySelector('#toggle-menu')
if (toggleMenu != null) {
    toggleMenu.addEventListener('click', () => {
        toggleMenu.classList.toggle('is-open')
        document.querySelector('#main-navigation').classList.toggle('is-open')
    })
}

const contactBtn = document.querySelector('#contact-btn')
if (contactBtn != null) {
    contactBtn.addEventListener('mousedown', () => {
        document.querySelector('#main-navigation').classList.remove('is-open')
    })
}

const donateBtn = document.querySelector('#donate-btn')
if (donateBtn != null) {
    donateBtn.addEventListener('mousedown', () => {
        document.querySelector('#main-navigation').classList.remove('is-open')
    })
}


// Open main navigation if you click on the hamburger
const header = document.querySelector('header'),
heroHome = document.querySelector('#heroHome'),
heroStars = document.querySelector('#heroStars'),
registrationForm = document.querySelector('#registrationForm');
let lastScrollY = window.scrollY;
let ticking = false;

const handleScroll = () => {
    const scrollPosition = window.scrollY

    if (
      ((scrollPosition > 100 && scrollPosition > lastScrollY) ||
      scrollPosition + window.innerHeight >
      document.documentElement.scrollHeight + 45)
    ) {
        header.classList.add('translate-y-[-100%]')
    } else if (scrollPosition < lastScrollY) {
        header.classList.remove('translate-y-[-100%]')
    }

    lastScrollY = scrollPosition
}

window.addEventListener('scroll', handleScroll)

const getElementTopPosition = (element: HTMLElement) => {
    let top = 0;
    while (element) {
        top += element.offsetTop;
        element = (element.offsetParent as HTMLElement);
    }
    return top;
};

const handleBgFixed = () => {
    const scrollPosition = window.scrollY;

    if (heroHome && heroStars) {
        // Adjust the background position
        (heroHome as HTMLElement).style.backgroundAttachment = 'scroll';
        (heroHome as HTMLElement).style.backgroundSize = '100% auto';
        (heroHome as HTMLElement).style.backgroundPositionX = '0';
        const topPosition = getElementTopPosition((heroHome as HTMLElement)) - 100;
        (heroHome as HTMLElement).style.backgroundPositionY = scrollPosition + topPosition + 'px';

        (heroStars as HTMLElement).style.backgroundAttachment = 'scroll';
        (heroStars as HTMLElement).style.backgroundSize = '100% auto';
        (heroStars as HTMLElement).style.backgroundPositionX = '0';
        (heroStars as HTMLElement).style.backgroundPositionY = scrollPosition + topPosition - (heroHome as HTMLElement).offsetHeight + 'px';
    }

    if(registrationForm) {
        // Adjust the background position
        (registrationForm as HTMLElement).style.backgroundAttachment = 'scroll';
        (registrationForm as HTMLElement).style.backgroundSize = '100% auto';
        (registrationForm as HTMLElement).style.backgroundPositionX = '0';
        const topPosition = getElementTopPosition((heroHome as HTMLElement)) - 100;
        (registrationForm as HTMLElement).style.backgroundPositionY = scrollPosition + topPosition + 'px';
    }

    lastScrollY = scrollPosition;
    ticking = false;
};

const onScroll = () => {
    if (!ticking) {
        window.requestAnimationFrame(handleBgFixed);
        ticking = true;
    }
};

if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
    window.addEventListener('scroll', onScroll);
    handleBgFixed()
}

// Create an instance of OiForm for each element
const oiFormElements = document.querySelectorAll('.js-oi-form');
oiFormElements.forEach((element) => {
    new OiForm(element as HTMLElement);
});

const oiSnapSliders = document.querySelectorAll('.js-oi-snap-slider');
oiSnapSliders.forEach((element) => {
    new OiSnapSlider(element as HTMLElement);
});

const oiGalleries = document.querySelectorAll('.js-oi-gallery');
oiGalleries.forEach((element) => {
    new OiGallery(element as HTMLElement);
});

const oiFancybox = document.querySelectorAll('.js-oi-fancybox');
oiFancybox.forEach((element) => {
    new OiFancyBox(element as HTMLElement);
});
