class OiGallery {
    private gallery: HTMLElement;
    private modalGalleries: HTMLElement;
    private modalGallery: HTMLElement;
    private modalBackdrop: HTMLElement;
    private currentImage: HTMLImageElement | null;
    private prevBtn: HTMLButtonElement;
    private nextBtn: HTMLButtonElement;
    private closeBtn: HTMLButtonElement;
    private modalImage: HTMLImageElement | null;
    private onLoadImage: HTMLImageElement | null;
    private images: NodeListOf<HTMLImageElement>;
    private assetKey: string;
    private currentIndex: number;
    private touchStartX: number;
    private touchEndX: number;

    constructor(gallery: HTMLElement) {
        this.gallery = gallery;
        this.images = gallery.querySelectorAll("img");
        this.modalGalleries = gallery.querySelector("#galleries") as HTMLElement;
        this.modalGallery = gallery.querySelector("#gallery") as HTMLElement;
        this.modalImage = gallery.querySelector("#galleryImage") as HTMLImageElement;
        this.modalBackdrop = gallery.querySelector("#galleryBackdrop") as HTMLElement;
        this.prevBtn = gallery.querySelector("#galleryPrev") as HTMLButtonElement;
        this.nextBtn = gallery.querySelector("#galleryNext") as HTMLButtonElement;
        this.closeBtn = gallery.querySelector("#galleryClose") as HTMLButtonElement;

        // Find the image if the hash matches
        if (window.location.hash) {
            this.onLoadImage = Array.from(this.images).find((image) => image.src.includes(window.location.hash.substring(1))) || null;
        }

        for (let image of this.images) {
            image.addEventListener("click", () => this.showImageInModal(image));
        }

        if (this.modalBackdrop) {
            this.modalBackdrop.addEventListener("click", this.removeHash.bind(this));
        }

        if (this.closeBtn) {
            this.closeBtn.addEventListener("click", this.removeHash.bind(this));
        }

        if (this.prevBtn) {
            this.prevBtn.addEventListener("click", this.showPrevImage.bind(this));
        }

        if (this.nextBtn) {
            this.nextBtn.addEventListener("click", this.showNextImage.bind(this));
        }

        // Add touch event listeners for swipe detection
        this.modalGallery.addEventListener("touchstart", this.handleTouchStart.bind(this), false);
        this.modalGallery.addEventListener("touchmove", this.handleTouchMove.bind(this), false);
        this.modalGallery.addEventListener("touchend", this.handleTouchEnd.bind(this), false);

        this.currentImage = null;
        this.currentIndex = -1;
        this.touchStartX = 0;
        this.touchEndX = 0;
    }

    handleTouchStart(event: TouchEvent) {
        this.touchStartX = event.changedTouches[0].screenX;
    }

    handleTouchMove(event: TouchEvent) {
        this.touchEndX = event.changedTouches[0].screenX;
    }

    handleTouchEnd() {
        if (this.touchEndX < this.touchStartX - 50) {
            this.showNextImage();
        } else if (this.touchEndX > this.touchStartX + 50) {
            this.showPrevImage();
        }
    }

    showImageInModal(image: HTMLImageElement) {
        this.currentImage = image;
        this.currentIndex = Array.from(this.images).indexOf(image);
        this.updateNavigationButtons();
        this.showImage(image.src, image.alt);
    }

    showImage(src: string, alt: string) {
        const assetKey = src.split('jcr:')[1];
        this.assetKey = assetKey.split('/')[0];
        this.modalGallery.id = this.assetKey;
        if (this.modalImage) {
            this.modalImage.src = src;
            this.modalImage.alt = alt;
            this.modalImage.classList.remove('opacity-0');
        }
        window.location.hash = this.assetKey;

        if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
            this.modalGalleries.classList.add('invisible');
        }

        this.modalGallery.classList.add('is-open');
        document.documentElement.classList.add('modal-open'); // Block scrolling
    }

    showPrevImage() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            const prevImage = this.images[this.currentIndex];
            this.showImage(prevImage.src, prevImage.alt);
            this.currentImage = prevImage;
            this.updateNavigationButtons();
        }
    }

    showNextImage() {
        if (this.currentIndex < this.images.length - 1) {
            this.currentIndex++;
            const nextImage = this.images[this.currentIndex];
            this.showImage(nextImage.src, nextImage.alt);
            this.currentImage = nextImage;
            this.updateNavigationButtons();
        }
    }

    updateNavigationButtons() {
        if (this.prevBtn) {
            this.prevBtn.disabled = this.currentIndex <= 0;
            this.prevBtn.classList.toggle('opacity-50', this.currentIndex <= 0); // Make button semi-transparent when disabled
        }
        if (this.nextBtn) {
            this.nextBtn.disabled = this.currentIndex >= this.images.length - 1;
            this.nextBtn.classList.toggle('opacity-50', this.currentIndex >= this.images.length - 1); // Make button semi-transparent when disabled
        }
    }

    removeHash() {
        this.modalGallery.classList.remove('is-open');
        document.documentElement.classList.remove('modal-open'); // Enable scrolling
        if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
            this.modalGalleries.classList.remove('invisible');
        }

        setTimeout(() => {
            const scrollY = window.scrollY;
            window.location.hash = '';
            this.onLoadImage = null;
            this.assetKey = 'gallery';
            this.modalGallery.id = this.assetKey;

            if (this.modalImage) {
                this.modalImage.classList.add('opacity-0');
                this.modalImage.src = '';
                this.modalImage.alt = '';
            }

            window.scrollTo(0, scrollY);
        }, 250);
    }
}

export default OiGallery;
