class OiSnapSlider {

    private slider: HTMLElement | null
    private track: HTMLElement | null
    private prev: HTMLElement | null
    private next: HTMLElement | null

    constructor(snapSlider: HTMLElement) {
        this.slider = snapSlider || null;
        this.track = snapSlider.querySelector("[data-slider-track]") || null;
        this.prev = snapSlider.querySelector("[data-slider-prev]") || null;
        this.next = snapSlider.querySelector("[data-slider-next]") || null;

        if (this.track) {
            if(this.prev) {
                this.prev.addEventListener("click", this.prevClick.bind(this));
            }
            if(this.next) {
                this.next.addEventListener("click", this.nextClick.bind(this));
            }
            if(this.track) {
                this.track.addEventListener("scroll", this.trackScroll.bind(this));
            }
        }

        this.trackScroll();

        window.addEventListener("resize", this.trackScroll.bind(this));
    }

    private prevClick() {
        this.track.scrollTo({
            left: this.track.scrollLeft - (this.track.firstElementChild as HTMLElement).offsetWidth,
            behavior: "smooth"
        });
    }

    private nextClick() {
        this.track.scrollTo({
            left: this.track.scrollLeft + (this.track.firstElementChild as HTMLElement).offsetWidth,
            behavior: "smooth"
        });
    }

    private trackScroll() {
        if (this.track.scrollWidth > this.track.clientWidth) {
            if (this.track.scrollLeft === 0) {
                this.prev.setAttribute("disabled", "disabled");
                this.prev.classList.add("opacity-0");
            } else {
                this.prev.removeAttribute("disabled");
                this.prev.classList.remove("opacity-0");
            }
            if (this.track.scrollLeft + this.track.clientWidth === this.track.scrollWidth) {
                this.next.setAttribute("disabled", "disabled");
                this.next.classList.add("opacity-0");
            } else {
                this.next.removeAttribute("disabled");
                this.next.classList.remove("opacity-0");
            }
        } else {
            this.prev.setAttribute("disabled", "disabled");
            this.next.setAttribute("disabled", "disabled");
            this.prev.classList.add("opacity-0");
            this.next.classList.add("opacity-0");
        }
    }
}

export default OiSnapSlider
