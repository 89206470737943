import { createTemplate } from '../../helpers/create-template.js'
import { snakeToCamel } from '../../helpers/string-helper'
import EventBus from '../../helpers/EventBus'
import BrowserStorage from '../../helpers/browser-storage'

const template: HTMLTemplateElement | null = createTemplate(`
    <template>
        <style>
            .is-hidden {
                display: none;
            }
            .cookie-consent {
                position: fixed;
                bottom: 0;
                width: 100%;
            }
            .cookie-consent-main {
            }
            .cookie-consent-settings {
            }
        </style>

        <div class="js-cookie-consent cookie-consent is-hidden pt-xl sm:flex z-1 bg-myBgNight backdrop-blur-xl">

            <div class="container">
                <!-- Main -->
                <div class="js-cookie-consent-main w-full sm:flex gap-md items-end justify-between cookie-consent-main">
                  <div class="pb-xl">
                    <h3 data-attribute="banner-title" class="text-lg mb-lg">We use cookies on our website</h3>
                    <p data-attribute="banner-text" class="text-base">We use cookies on our website</p>
                  </div>
                    <div class="pb-xl flex gap-md">
                        <button data-attribute="accept-all" class="js-accept-all oi-button-secondary">Alle Akzeptieren</button>
                        <button data-attribute="open-settings" class="js-open-settings oi-button-secondary">Einstellungen</button>
                    </div>
                </div>
    
                <!-- Settings -->
                <div class="js-cookie-consent-settings w-full sm:flex gap-md items-end justify-between cookie-consent-settings is-hidden">
                  <div class="pb-xl">
                        <h3 class="text-lg font-rubik" data-attribute="cookie-settings-title">Cookie Settings</h3>
        
                        <div class="flex gap-md">
                            <div>
                                <input name="functional" id="functional" type="checkbox" checked disabled>
                                <label for="functional" data-attribute="functional-label">Functional</label>
                            </div>
                            <div>
                                <input name="analytics" id="analytics" type="checkbox">
                                <label for="analytics" data-attribute="analytics-label">Analytics</label>
                            </div>
                            <div>
                                <input name="marketing" id="marketing" type="checkbox">
                                <label for="marketing" data-attribute="marketing-label">Marketing</label>
                            </div>
                        </div>
                    </div>
                    
                    <div class="pb-xl flex gap-md pb-xl">
                        <button data-attribute="accept-all" class="js-accept-all-settings oi-button-secondary">Alle akzeptieren</button>
                        <button data-attribute="save-settings" class="js-save-settings oi-button-secondary" >Speichern</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
`)


export default class CookieConsent extends HTMLElement {

    cookieNameFunctional: string = 'hasFunctionalConsent'
    cookieNameAnalytics: string = 'hasAnalyticsConsent'
    cookieNameMarketing: string = 'hasMarketingConsent'

    private acceptAllButton: HTMLElement
    private acceptAllSettingsButton: HTMLElement
    private openSettingsButton: HTMLElement
    private saveSettingsButton: HTMLElement
    private cookieConsentContainer: HTMLElement
    private mainContainer: HTMLElement
    private settingsContainer: HTMLElement
    private inputAnalytics: HTMLInputElement
    private inputMarketing: HTMLInputElement

    /**
     * Add all attributes ot you web component.
     * For attributeChangedCallback() callback to fire when an attribute changes, you have to observe the attributes.
      */

    static get observedAttributes() {
        return [
            'banner-title',
            'banner-text',
            'accept-all',
            'open-settings',
            'cookie-settings-title',
            'functional-label',
            'analytics-label',
            'marketing-label',
            'save-settings'
        ]
    }

    /**
     * The browser calls this method when the element is
     * added to the DOM.
     */
    connectedCallback() {
        console.log('Cookie Consent: Connected')
        this.appendChild(template.content.cloneNode(true))
        this.updateTextsfromAttributes()
        this.initializeComponent()
        console.log('Cookie Consent: Component Initialized')
    }

    /**
     * Called before connectedCallback() for every observed attribute
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            for (const item of CookieConsent.observedAttributes) {
                if (item === name) {
                    const propertyName = snakeToCamel(item)
                    this[propertyName] = newValue //Set text in property
                }
            }
        }
        this.updateTextsfromAttributes()
    }


    /**
     * Sets texts of tags with a data-attribute with webcomponents attribute value
     */
    updateTextsfromAttributes() {
        CookieConsent.observedAttributes.forEach((item) => {
            const propertyName = snakeToCamel(item)
            const selector = '[data-attribute=' + item + ']'
            this.querySelectorAll(selector).forEach((item) => {
                item.innerHTML = this[propertyName] //Get text from property and write to HTML
            })
        })
    }

    initializeComponent() {
        //Elements
        this.acceptAllButton = this.querySelector('.js-accept-all')
        this.acceptAllSettingsButton = this.querySelector('.js-accept-all-settings')
        this.openSettingsButton = this.querySelector('.js-open-settings')
        this.saveSettingsButton = this.querySelector('.js-save-settings')
        this.cookieConsentContainer = this.querySelector('.js-cookie-consent')
        this.mainContainer = this.querySelector('.js-cookie-consent-main')
        this.settingsContainer = this.querySelector('.js-cookie-consent-settings')
        this.inputAnalytics = this.querySelector('input#analytics')
        this.inputMarketing = this.querySelector('input#marketing')

        //Events
        this.acceptAllButton.addEventListener('click', () => {
            console.log('accept-all click')
            this.acceptAllCookies()
        })
        this.acceptAllSettingsButton.addEventListener('click', () => {
            console.log('accept-all-settings click')
            this.acceptAllCookies()
        })
        this.openSettingsButton.addEventListener('click', () => {
            console.log('Open-settings click')
            this.showSettings()
        })
        this.saveSettingsButton.addEventListener('click', () => {
            console.log('Save-settings click')
            this.saveUserSettings()
        })

        //Events
        EventBus.$on('openCookieBanner', () => {
            console.log('Cookie consent: Event openCookieBanner')
            this.showCookieConsent()
        })

        if(!BrowserStorage.getBooleanCookie(this.cookieNameFunctional)) {
            //show cookie banner
            this.showCookieConsent()
        }
    }

    showCookieConsent() {
        this.hideSettings()
        this.updateCheckBoxes()
        this.cookieConsentContainer.classList.remove('is-hidden')
    }

    hideCookieConsent() {
        this.cookieConsentContainer.classList.add('is-hidden')
    }

    showSettings() {
        console.log('showSettings()')
        this.mainContainer.classList.add('is-hidden')
        this.settingsContainer.classList.remove('is-hidden')
    }

    hideSettings() {
        console.log('hideSettings()')
        this.settingsContainer.classList.add('is-hidden')
        this.mainContainer.classList.remove('is-hidden')
    }

    /**
     * Sets all cookies and closes banner
     */
    acceptAllCookies() {
        BrowserStorage.setCookie(this.cookieNameFunctional, true, 365) // Always true
        BrowserStorage.setCookie(this.cookieNameAnalytics, true, 365)
        BrowserStorage.setCookie(this.cookieNameMarketing, true, 365)

        this.emitEvents()
        this.hideCookieConsent()
    }

    saveUserSettings() {
        BrowserStorage.setCookie(this.cookieNameFunctional, true, 365) // Always true
        BrowserStorage.setCookie(this.cookieNameAnalytics, this.inputAnalytics.checked, 365)
        BrowserStorage.setCookie(this.cookieNameMarketing, this.inputMarketing.checked, 365)

        this.emitEvents()
        this.hideCookieConsent()
    }

    /**
     * Updates checkboxes according to cookies
     */
    updateCheckBoxes() {
        this.inputAnalytics.checked = BrowserStorage.getBooleanCookie(this.cookieNameAnalytics)
        this.inputMarketing.checked = BrowserStorage.getBooleanCookie(this.cookieNameMarketing)
    }


    emitEvents() {
        console.log("Cookie Consent: Emitting event 'cookieConsent'")
        EventBus.$emit('cookieConsent')
    }

}
